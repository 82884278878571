const img = '';

export default [
  {
    img: img,
    title: 'First encounter: Taipei, 2015',
    description: `They first met each other at an Indonesian students’ event in Taiwan held by PERPITA. 
    One as the event organiser, and another as the attendant. Nothing happened actually.`,
  },
  {
    img: img,
    title: 'Closer: Taipei, summer 2016',
    description: `Later the attendant was interested in becoming the PERPITA committee, so she applied, 
    and her application captivated him, who at that time was the newly appointed vice president with 
    passionate new ideas. The organisation projects brought them closer, unintentionally for her, not so sure about him.`,
  },
  {
    img: img,
    title: 'The birth of a new couple: Taipei, 4 Dec 2016',
    description: `Despite knowing they could only be close in distance when both were in Taipei, they gave it a try. 
    Occasional LDR during the winter and summer holidays did not stop them.`,
  },
  {
    img: img,
    title: 'Welcoming Bubi Bunny: Taipei, 26 Oct 2017',
    description: `Their stronger commitment started earlier before the engagement ring. A tiny bunny named Bubi they 
    took care of together made their bond deeper and more fun. The bunny had stayed with him in Taipei, now it lives 
    with her in Jakarta, and soon the three of them will reunite forever in Semarang.`,
  },
  {
    img: img,
    title: 'Next level LDR: Taipei-Leicester, autumn 2018',
    description: `As if the existing LDR was nothing, she left Taipei for Leicester. The different time zone LDR was only 
    for a short period but the effects were for a lifetime. He was her biggest supporter in pursuing her goal even since 
    the preparation, and not only that…`,
  },
  {
    img: img,
    title: 'Second anniversary: Paris, 4 Dec 2018',
    description: `He has long planned to visit her in England and prepared a surprise trip for their second anniversary. 
    It was a success, she cried happy tears.`,
  },
  {
    img: img,
    title: 'Longest LDR: Taipei-Jakarta, 2020',
    description: `We all know what happened in 2020. They did not meet in person at all for 6 months straight, 
    and that was the longest LDR period of them. Seems short for some but not for them, so let’s just drop the record here.`,
  },
  {
    img: img,
    title: 'Proposal: Fairbanks, 29 Oct 2022',
    description: `She has always thought that to be proposed under the stars or the aurora is the most romantic. He decided 
    to make the latter come true. She did not know before that the aurora could be seen from Alaska, nor that they were going 
    to Alaska until three days prior. Anyway, she said yes!`,
  },
  {
    img: img,
    title: 'Tingjing: Jakarta, 4 Dec 2022',
    description: `His family came from Semarang to officially ask permission from her family for their son to marry their daughter. 
    Both families were formally united since that day.`,
  },
  {
    img: img,
    title: 'Pre-wedding: Melbourne, 16-17 Mar 2023',
    description: `He took her to his favourite city in autumn, his favourite season, to shoot beautiful photos and make new memories. 
    Best decision ever.`,
  },
  {
    img: img,
    title: 'Sangjit: Jakarta, 5 Nov 2023',
    description: `An intimate celebration with her big family for their upcoming big day.`,
  },
  {
    img: img,
    title: 'Wedding: Semarang, 18 Nov 2023',
    description: `We cordially request your presence to witness the celebration of their union, the end of a long-distance 
    relationship, for finally Liel is with Gab #finalLIELwithGAB`,
  },
];
